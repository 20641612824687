import classNames from "classnames";
import styles from './styles.module.css';
import { useContext } from "react";
import { WishlistContext } from "../../../../context/WishlistContext";
import { algoliaIndices } from "../../../../utils/constants";
import { LookingSimilar } from "@algolia/recommend-react";
import { getLooksSameFilters, recommendClient, RelatedItem } from "../../../sections/RelatedProducts";
import { useShopifyItems } from "../../../../data-fetching/useShopifyItems";
import { CanonicalItem } from "../../../../data-fetching/canonicalItem";
import { YourRecommendedItemsStoryblok } from "./types";
import { areAllNotNullOrUndefined, extractHandlesFromWatchlistItems, toString } from "./utils";
import { Skeleton } from "../../../account/Common/Skeleton";

interface HeaderProps {
  text: string;
}

const RecommendedItemsHeader = ({ text }: HeaderProps) => {
  return <h3 className={classNames(styles.title, 'display-18-caps')}>{text ?? 'For You'}</h3>;
};

interface Props {
    blok?: YourRecommendedItemsStoryblok;
}

export const YourRecommendedItems = ({ blok }: Props) => {
    const { text } = blok || {};
    const { wishlist, wishlistLoading } = useContext(WishlistContext);

    const firstTenWatchlistItemHandles = extractHandlesFromWatchlistItems(wishlist?.listcontents ?? [], 10);
    const shopifyProducts = useShopifyItems(firstTenWatchlistItemHandles);
    const variantIds = shopifyProducts.items.map(item => item?.variant_id);
    const variantIdsLoading = !areAllNotNullOrUndefined(variantIds);
     
    const isLoading = wishlistLoading || variantIdsLoading;

    const filters = shopifyProducts.items?.filter((item): item is CanonicalItem => !!item)
        .map(item => getLooksSameFilters(item) || [])
        .map(filters => filters.filter(Boolean).join(' AND '))
        .join(' OR ');

    if (!variantIds.length) return null;

    return (
        <section className={classNames('Section')}>
            {isLoading ?
             <div className="px-3 py-6 Container">
                <div className="grid gap-y-6 gap-x-2 grid-cols-3 md:grid-cols-6 xl:gap-x-4 mt-14">
                    {Array.from({ length: 6 }).map((_, index) => (
                    <Skeleton key={index} height={200} width={'auto'} />
                    ))}
                </div>
            </div> :
            <div className={styles.container}>
                <div className="relative">
                    <div className={styles.products}>
                        <LookingSimilar
                            classNames={{
                                root: 'py-6 Container',
                                list: 'mt-4 grid gap-y-6 gap-x-2 grid-cols-3 md:grid-cols-6 xl:gap-x-4',
                            }}
                            threshold={65}
                            recommendClient={recommendClient}
                            indexName={algoliaIndices.shopifyProducts}
                            objectIDs= {variantIds.map(toString)}
                            queryParameters={{ filters }}
                            itemComponent={RelatedItem}
                            headerComponent={(props) => <RecommendedItemsHeader {...props} text={text ?? 'For You'} />}
                            maxRecommendations={6}
                        />
                    </div>
                </div>
            </div>}
        </section>
    );
};