import { SwymListItem } from "../../../../modules/@swym/swym.service";
import { isNotNullOrUndefined } from "../../../../utils/objectHelpers";

export const extractHandlesFromWatchlistItems = (watchlistItems: SwymListItem[], limit: number) => {
    const watchlistSegment = watchlistItems.slice(0, limit ?? 5);
    const watchlistItemHandles = watchlistSegment.map((item) => item.du.split('/').pop()?.split('?').shift() || '');
    return watchlistItemHandles;
}

export const areAllNotNullOrUndefined = <Entry,>(entries: Entry[]): entries is Exclude<Entry, null | undefined>[] => {
    return entries.every(isNotNullOrUndefined);
}

export const toString = (value: any): string => {
    return `${value}`;
}