import { useQueries, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { CanonicalItem } from "./canonicalItem";
import { convertShopifyProductToCanonicalItem, fetchShopifyItemByHandle } from "./useShopifyItem";
import { getQueryClient } from "../context/QueryContext";

export const useShopifyItems = (handles: string[], options?: { alwaysFresh?: boolean }) => {
  const queries: UseQueryOptions<CanonicalItem | null, Error>[] = handles.map((handle) => ({
    queryKey: ['items', handle],
    queryFn: async () => {
      if (['thriftbox', 'thriftbag'].includes(handle)) return null;
      return fetchShopifyItemByHandle({ handle }).then(convertShopifyProductToCanonicalItem);
    },
    staleTime: options?.alwaysFresh ? 1000 : 1000 * 60 * 5, // 1 second or 5 min
    cacheTime: 1000 * 60 * 5, // 5 min
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchInterval: undefined,
    refetchIntervalInBackground: undefined,
  }));

  const queryResults: UseQueryResult<CanonicalItem | null, Error>[] = useQueries({ queries }, getQueryClient());

  return {
    isLoading: queryResults.some((query) => query.isLoading),
    error: queryResults.find((query) => query.error)?.error || null,
    items: queryResults.map((query) => query.data),
  };
};