import { Brand, BrandType } from '../../types/brands';
import { fetchGetJSON } from '../api-helpers';
const THRIFT_BASE_URL = process.env.NEXT_PUBLIC_THRIFT_BASE_API;

export const checkBrand = async (brand: string): Promise<Brand[] | undefined> => {
  try {
    const response: Brand[] = await fetchGetJSON(`${THRIFT_BASE_URL}/public/v1/brands?search=${encodeURIComponent(brand)}&limit=6`);
    
    // we don't want vintage or b2b supply brands to show in the brand checker to c2b customers
    // we always want to return 3 results
    const filteredResults = response?.filter((res) => res.brand_type !== 'vintage' && !isB2BSupplyBrand(res.brand_name));

    return filteredResults
      .slice(0, 3)
      .map(brand => {
        if (BRANDS_TO_MARK_AS_NOT_ACCEPTED.has(brand.brand_name)) {
          return {
            ...brand,
            brand_type: BrandType.NOT_ACCEPTED,
          }
        } else {
          return brand;
        }
      });

  } catch (err) {
    // Sentry.captureException(err);
    console.log('error is', err);
  }
};

// in order to process B2B supply quickly with different brand types to their C2B equivalents, we create distinct B2B brands then map their names to a customer-facing equivalent for listings + orders
const B2B_SUPPLY_BRANDS_DISPLAY_MAPPINGS: Record<string, string> = {
    'Asos B2B': 'Asos',
};

const isB2BSupplyBrand = (brandName: string) => {
    if (B2B_SUPPLY_BRANDS_DISPLAY_MAPPINGS.hasOwnProperty(brandName)) return true;

    return false;
}

const BRANDS_TO_MARK_AS_NOT_ACCEPTED = new Set([
"Zara",
"Next",
"New Look",
"Topshop",
"River Island",
"MNG",
"Gap",
"Oasis",
"Warehouse",
"Hollister",
"Bershka",
"Monki",
"Urban Outfitters",
"Stradivarius",
"Pull&Bear",
"Gymshark",
"Asos B2B",
"Mango",
"Under Armour",
"Motel",
"Quiz",
"Collusion",
"Roman",
"Accessorize",
"BDG",
"NA-KD",
"Mountain Warehouse",
"Oh Polly",
"Zara Basic",
"AX Paris",
"Fabletics",
"weird fish",
"Esprit",
"Yours",
"Louche",
"Reserved",
"Karrimor",
"Pieces",
"Noisy May",
"Yumi",
"Slazenger",
"Old Navy",
"Debenhams",
"St. Michael",
"Oysho",
"Jane Norman",
"VILA",
"Pins and Needles",
"Max Studio",
"Capsule",
"Glassons",
"Evans",
"Kaliko",
"Sandwich",
"Gilly Hicks",
"AYBL",
"crew",
"Jd Williams",
"Country Casuals",
"Kaleidoscope",
"Viyella",
"Esmara",
"Farah",
"Pimkie",
"Planet",
"The Collection",
"MP",
"Girl in Mind",
"Love & Roses",
"TRF",
"Limited Collection",
"Pretty Lavish",
"Selected",
"Fount",
"Kookai",
"Roxy",
"Subdued",
"Original Penguin",
"KEW",
"JDY",
"Blue 73",
"Princess Polly",
"Fashion Union",
"Trendyol",
"Indigo",
"Ivy Park",
"Havaianas",
"Saint Tropez",
"B.Young",
"Express",
"Billie & Blossom",
"Promod",
"Debut",
"South Beach",
"Part Two",
"Dunnes",
"Eastex",
"Kimchi Blue",
"Raid",
"Sisley",
"Only & Sons",
"Country Road",
"Urban Bliss",
"Urban Revivo",
"Morgan",
"Betty Barclay",
"Selected Homme",
"Naanaa",
"Trafaluc",
"O'Neill",
"Silence + Noise",
"Witchery",
"Cheap Monday",
"Missy Empire",
"4TH & Reckless",
"Umbro",
"Adini",
"N√ºmph",
"JustFab",
"Stile Benetton",
"Vesper",
"&et",
"Everlast",
"NW3",
"James Lakeland",
"Together",
"Hilfiger Denim",
"Velvet",
"Soyaconcept",
"HEATTECH",
"MinkPink",
"Olsen",
"collection pimkie",
"Tenki",
"FP Beach",
"Naf Naf",
"HIIT",
"Native Youth",
"Victoria's Secret Pink",
"Vince Camuto",
"Alphalete",
"Chicme",
"Domyos",
"Aerie",
"Miso",
"Spotlight",
"Van Heusen",
"Gap Fit",
"Max",
"Springfield",
"koton",
"Sfera",
"Julien Macdonald",
"Girls on Film",
"H by Henry Holland",
"Kangol",
"DASH",
"Kenneth Cole",
"Crosshatch",
"RO&ZO",
"Julipa",
"tally weijl",
"s.Oliver",
"Oh My Love",
"Pour Moi",
"Maison Scotch",
"love&roses",
"Vila Clothes",
"Calliope",
"Lost Ink",
"Aeropostale",
"Hush Puppies",
"unique 21",
"Luxe",
"Max&Co",
"Victoria Sport",
"Commense",
"Superdown",
"Hanes",
"Hilfiger",
"Wallis",
"Luke",
"YAYA",
"Bay",
"Ann Harvey",
"Faithful & True Mantaray",
"market and spruce",
"Orsay",
"Bellfield",
"Lascana",
"Definitions",
"icyzone",
"Splendid",
"Guide London",
"Petit Bateau",
"Sweew√´",
"Eddie Bauer",
"The Masai",
"Glassworks",
"Edikted",
"Elle",
"Innocence",
"Cable & Gauge",
"Kyodan",
"Next Level Apparel",
"Seen Worn Kept",
"Hype",
"IKKS",
"James Pringle",
"Marble",
"bpc bonprix collection",
"Dunnes Stores",
"Poem",
"dhb",
"mistress rocks",
"TIKIBOO",
"Saba",
"Clockhouse",
"Joe's",
"True Decadence",
"Meshki",
"ALEX & Co",
"Full Circle",
"Sportsgirl",
"WalG",
"Laundry by Shelli Segal",
"next x morris & co",
"Rydale",
"Vanquish",
"International Concepts",
"Honey Punch",
"Mossimo",
"Portmans",
"Review",
"Uttam Boutique",
"Fcuk",
"Gardeur",
"Rare",
"Gharani Strok",
"2XU",
"Allen Solly",
"Chico's",
"Millie Mackintosh",
"41 Hawthorn",
"Voodoo Vixen",
"Tna",
"cotton on body",
"Sheike",
"IISLA & BIRD",
"Adrienne Vittadini",
"Darling",
"Stella",
"Gray & Osbourn",
"Unique",
"Autre Ton",
"Joy",
"Taifun",
"echt",
"AG",
"Arizona",
"bianca",
"Ginger",
"Kenar",
"R&M Richards",
"Stromberg",
"Yogalicious",
"yuki",
"One Teaspoon",
"Hotsquash",
"almost famous",
"Wardrobe",
"DAY Birger et Mikkelsen",
"ELVI",
"Goldie",
"Monoprix Femme",
"Nightingales",
"Des Petits Hauts",
"Duchamp",
"Fiore",
"Gottex",
"Kiabi",
"Mtwtfss weekday",
"park avenue",
"powerhold",
"Ravel",
"Adiva",
"Closet. London",
"883 Police",
"asquith",
"Atmos & Here",
"Marina Rinaldi",
"Zibi London",
"Angeleye",
"Nine Savannah Miller",
"sisterhood",
"An'ge",
"Callaway",
"capri",
"Head over Heels",
"Hearts & Roses",
"Jachs",
"Majestic",
"Marcs",
"Olymp",
"Silvian Heach",
"Tiger of Sweden",
"Trussardi",
"Wilfred free",
"Zoe Karssen",
"True Violet",
"Khaadi",
"Incerun",
"kendall +kylie",
"lovedrobe",
"Anthology Paris",
"Diva",
"Love Triangle",
"LTB",
"nimble",
"Wolsey",
"Ariella",
"Buff",
"Common People",
"C. Valentyne",
"Imperial",
"Jessica",
"Kangaroos",
"La Senza",
"Michele Hope",
"Pier One",
"Rose Olive",
"Sacred Hawk",
"Seven 7",
"St. George by Duffer",
"Tiffosi",
"Yonex",
"Club London",
"Edited",
"Junarose",
"Les Petites",
"Motel Rocks",
"Kley",
"All Fenix",
"Amaryllis",
"Craft",
"Day Birger & Mikkelsen",
"Figleaves",
"Jackpot",
"C&C California",
"L'olive verte",
"North Ridge",
"Oysho Sport",
"A Wear",
"Lily",
"Marina Kaneva",
"Mary Portas",
"Melissa",
"Polo Jeans Company",
"Rip N Dip",
"Spray Way",
"Voi",
"Sistaglam",
"GF grass - fields",
"Call it Spring",
"Nobull",
"Sanctuary",
"Twenty8twelve",
"gym + coffee",
"Merona",
"BEBO",
"Chesca",
"El Ganso",
"FC Jeans",
"Heine",
"Heritage",
"Ipanema",
"Layer 8",
"MAC",
"Miss Captain",
"rant & rave",
"Remus Uomo",
"Smashed Lemon",
"Twiggy",
"belle poque",
"CosterCopenhagen",
"Pink Clove",
"Anokhi",
"Nicole Miller",
"Protest",
"koala bay",
"Boody",
"dstruct",
"Los Angeles Apparel",
"Abercrombie Vintage",
"A&G",
"Assembly Label",
"Athletic Works",
"Atlas For Men",
"Coline",
"Cutter & Buck",
"free spirit",
"Hamells",
"House of Denim",
"J&L",
"John Charles",
"Keds",
"Maggy London",
"Michele",
"Onzie",
"Peak Performance",
"Rohnisch",
"Silou",
"Sin√©quanone",
"Speedo",
"Strenesse",
"sundried",
"Toi&Moi",
"Playboy",
"AQ/AQ",
"Holly Willoughby",
"margaret m",
"American Outfitters",
"Jeff & Co",
"signature8",
"Vera tucci",
"Fenchurch",
"ronen chen",
"Alice Temperley",
"Amari",
"August Silk",
"Blood Brother",
"daily practice",
"dedicated",
"Globe",
"Gollehaug",
"holland & sherry",
"Holly & whyte",
"In Vogue",
"Little White Lies",
"Loom",
"Minelli",
"M Life",
"Pocket sport",
"Rascal",
"scholl",
"Twist&Tango",
"veronika maine",
"Verse",
"Wego",
"Faded",
"Daniel rainn",
"D. Anna",
"89TH & Madison",
"Foil",
"Grace",
"Kelly Brook",
"Nicole",
"Onado",
"See U Soon",
"AQUA",
"HUNKYDORY",
"Zella",
"afends",
"Bartolini",
"Beaumont Organic",
"Brixton",
"Butterfly Twists",
"Crooked Tongues",
"cut loose",
"District",
"Easy Wear",
"Eden",
"Escada Sport",
"Fellini",
"Harris Wilson",
"Lauren Vidal",
"Lilley And Skinner",
"Massimo Fabbro",
"New York laundry",
"Noli",
"Nooki",
"Notch",
"P.E Nation",
"PE Nation",
"Peter alexander",
"Rebel",
"Renato Nucci",
"Resume",
"Shellys",
"Stella Forest",
"St-Martins",
"Track and Field",
"yerse",
"et vous",
"Hello Molly",
"John Zack",
"Josefine HJ x NA-KD",
"Mitchell & Ness",
"Peace + Love",
"Colmar",
"Karen by Simonsen",
"little wings factory",
"Misskoo",
"More Mile",
"Scarlet",
"Tularosa",
"Linea Weekend",
"Lucas Hugh",
"Revolution",
"UNTUCKit",
"Aerosoles",
"Beverly Hills Polo Club",
"Casall",
"Choose Love",
"codello",
"Danier",
"El Corte Ingl√©s",
"elements/amanda wakeley",
"Ella Moss",
"Entracte",
"Finesse",
"Flamingo's",
"Gaiam",
"Harvey Nichols",
"Honey",
"Infinite by Wed2b",
"Intermix",
"Iron Fist",
"Ivanka Trump",
"J",
"Jo Mercer",
"Julian Macdonald",
"Just Female",
"kisaiya",
"Lily Ella",
"limehaus",
"Madcap",
"Millet",
"Mondi",
"Oliver Brown",
"Pilgrim",
"Porter + ASH",
"Preen by Thornton Bregazzi",
"Prince",
"Princesse Tam-tam",
"Savoy Taylors Guild",
"Sperry",
"Studio W",
"surfanic",
"With Jean",
"Woolmark",
"limited Edition",
"Levante",
"Phoenix & Feather",
"Tiffany Rose",
"Azarey",
"Cece",
"Clements Ribeiro portobello",
"Khujo Brand",
"Levete Room",
"Miss Floral",
"Noir",
"scarlet rocks",
"Seven Wonders",
"Ulla Popken",
"England",
"Gym King (GK)",
"Matinique",
"Rabe",
"A√©ropostale",
"AMG Petronas F1",
"Antioch",
"Beachlunchlounge",
"Black Label Paul Smith",
"Blancheporte",
"blue illusion",
"Cashmere Sutton Studio",
"Coldwater Creek",
"colette",
"cove",
"Cruz",
"Cuyana",
"David's Bridal",
"Descente",
"Designers Society",
"Doris Streich",
"Edinburgh since 1437",
"Eider",
"Endless Rose",
"Fox Racing",
"Gaudi",
"gazelle",
"Gigi",
"Gill",
"Gul",
"Hod",
"Hoggs of Fife",
"ISTO.",
"izzue",
"Kesta",
"Kin by John Lewis",
"Kivari",
"LABELRAIL X COLLYER TWINS",
"Labelrail x Olivia & Alice",
"last exit to nowhere",
"Laurie",
"Le Chateau",
"Lili",
"Lipstick",
"Love, fire",
"Lucy",
"Maidenform",
"Maids to Measure",
"Marie Mero",
"M by moda",
"Mina",
"MK",
"Moon River",
"NYJD",
"Pablo",
"plush",
"RAYE",
"Realm & Empire",
"Riva",
"Rokit originals",
"Rose London",
"rumour",
"Sack''s",
"Samoon",
"Santinelli",
"Sigrid Olsen",
"Simple Retro",
"snidel",
"Stills",
"Sweet Pot",
"the odder side",
"Truly",
"Vanessa Wu",
"viktoria + woods",
"Zac Posen",
"Zavetti",
"Emma Willis Collection for Next",
"Gracia",
"Pressio",
"Adidas formation",
"Ana Alcazar",
"Apache",
"dixie",
"EI8TH HOUR",
"Emme Marella",
"Kanya London",
"No. ELEVEN",
"pimke",
"ranna gill",
"Rifle",
"gorgeous",
"Caract√©re",
"Corder London",
"SUB4",
"Sunday in Brooklyn",
"47 brand",
"Alannah Hill",
"Alxiliary",
"Antique Rogue",
"Baileys",
"Barbara Schwarzer",
"Belle Vere Notte",
"blues",
"Bonds",
"Camela",
"candida",
"Celeb Boutique",
"CKS",
"Coffee Shop",
"coin 1804",
"Comme √áa Ism",
"Cossac",
"Dagny London",
"Degre 7",
"DENHAM",
"Didier Parakian",
"Duffy",
"Elegance Paris",
"Enrico Coveri",
"Estella Bartlett",
"Faber",
"fenty",
"Gap Denim",
"Guide",
"Harry Hall",
"H.I.S",
"H&R",
"Hunza",
"HYRA",
"indian terrain",
"Ivana Helsinki",
"Jaime Mascaro",
"Jermyn Street Guild",
"J Jasper Conran",
"Joseph A.",
"Kalf",
"kangol & h&m",
"Kate Sylvester",
"Kelly",
"Kelsi Dagger",
"kendal + Kylie",
"Kule",
"LAMODA",
"lieblincsstuck",
"Lilith",
"Louenhide",
"Lulu Hun",
"Lumina",
"Maille Demoiselle",
"Mariella Rosati",
"Mary & clarks",
"Michaelis",
"Milan",
"Ministry of supply",
"Miss June",
"Mjus",
"Mud Jeans",
"My Essential Wardrobe",
"NERD UNIT",
"New Bridge",
"Nicholas Millington",
"Nines Collection",
"Nintendo",
"Nique",
"One Step",
"paco gil",
"Palmier London",
"Panama Jack",
"Persona by Marina Rinaldi",
"Petra",
"peyton primrose",
"Pia michi",
"Pistola",
"Polaris Bikewear",
"Prettyzys",
"Project X Paris",
"qllie quinn",
"Red Bull Racing",
"Rena Rowan",
"REWRITTEN",
"Route 66",
"Ryder & Amies",
"Sachi",
"School Rag",
"Season",
"Soul",
"Spiritual Gangster",
"Sseinse",
"Stefano Conti",
"Tenson",
"Thing Thing",
"Tripp NYC",
"Tweedmill",
"Via Spiga",
"Accessorise",
"grace & mila",
"Nine",
"Street one",
"Andrew Marc",
"B2",
"BALR.",
"Beyond Retro",
"by Malina",
"Cactus",
"Dark Pink",
"Sovere",
"suzanne bettley",
"two sisters",
"Zerres",
"ST Studio",
"Easy B",
"Herman Geist",
"It",
"Kookaburra",
"Marida",
"Mistress",
"Souke Sports",
"toffeln",
"XO",
"20:00",
"7th Hvn",
"abrand",
"Acapulco Gold",
"adam selman",
"Aideen Bodkin",
"Aimee by Peopletree",
"Alexander Dobell",
"All about eve",
"Amanda",
"angel circle",
"Anita",
"anti social social club",
"April May",
"Baird McNutt for J.Crew",
"Bambolina",
"Barneys New York",
"Beibaobao",
"Bella Blue",
"Biggie Best",
"Bloomingdale's",
"Bonart",
"Bonia",
"Box2",
"brava fabrics",
"Bulldog",
"bulle de savon",
"Cabrini",
"Carly Harris",
"Carmens",
"Cassis",
"CC (Country Casuals)",
"celia birtwell for topshop",
"Christopher Kane for Topshop",
"Christophe Sauvat",
"cigno nero",
"Club Med",
"Costa 8 Mani",
"darkfuture",
"denizen (levi's)",
"Denton Hats",
"Diab''less",
"DJ85¬∞c",
"Donald J Pliner",
"Driftwood",
"Duluth Trading Co",
"Eden Rock",
"Elvio Zanon",
"Eram",
"Fame and Partners",
"Filson",
"Finders",
"Fougue",
"Foxy",
"French Sole by Jane Winkworth",
"Gabriella",
"Garance ( Paris )",
"Garden of Angels",
"Garnet Hill",
"Gaudi Jeans",
"Geoffrey Beene",
"Gilmar",
"Goose and Gander",
"Gsus sindustries",
"Harmont & Blaine",
"Hispanitas",
"H&MX GHOST",
"hockerty",
"Hosbjerg",
"Indigenous",
"IVY Copenhagen",
"jac + jack",
"James Harvest",
"John Hanly",
"Joker",
"Justin Reece",
"jw Anderson x uniqlo",
"Kali Orea",
"Kate Sheridan",
"Komono",
"Laceys",
"La Martina",
"L.Credi",
"Leko",
"les 100 ciels",
"Le Saunda",
"Levelwear",
"Lilimill",
"lili sidonio",
"Linea Pelle",
"Little Marcel",
"Liviana Conti",
"Liz Cox",
"Lorbac",
"loreak mendian",
"Love and Confuse",
"Luella Grey",
"Luis Civit",
"LuluLaila",
"Lululemon x Roksanda",
"Magyann",
"maker's shirt",
"Maloja",
"Marc Ellis",
"MarmaDuke",
"Maura styled by Claudia Strater",
"Melo",
"Members Only",
"Mephisto",
"Merz b. Schwanen",
"Mil-Tec",
"Ministry of Style",
"Minnetonka",
"Moheda for Jigsaw",
"Moods of Norway",
"Morilee",
"Murray Brothers",
"My Bandage Dress",
"My Tribe",
"Nicholas Deakins",
"Nike acg",
"Niteline",
"NSF",
"NZA",
"OAK",
"Oasis x V&A",
"Orient499",
"Orna Farho",
"oscar banks",
"Panache",
"Pandora",
"Pankaj & Nidhi",
"Pantone Universe",
"Pariscope",
"Philippe Carat",
"PINK shirtmaker",
"Pioneros",
"Pitusa",
"Primitive Skateboarding",
"Rei",
"Rihanna for River Island",
"R Newbold",
"Road Rags Jean Co.",
  "Robe Legere",
"Ronald Joyce",
"Roundtree & Yorke",
"rue blanche",
"Santa Barbara Polo & Racquet Club",
"Scalpers",
"Scott",
"Selfridges",
"Selkie",
"Sergeant Pepper",
"Shirtaporter",
"Siggi",
"silver back gymwear",
"Skies Are Blue",
"smalto",
"Snowdonia",
"S.N.S. Herning",
"Softinos",
"Somerville Scarves",
"Sport b.",
"Studio Pollini",
"Sulu",
"Taking Shape",
"Tara woman",
"Teddy Smith",
"Tela",
"the fisher project",
"The good neighbour",
"Tina Taylor",
"Topo Designs",
"travelon",
"Twin Set ( Simona Barbieri )",
"Undefeated",
"Valleverde",
"Van Gils",
"Vineet Bahl",
"Vinti Andrews",
"Waterscapes",
"Wojas",
"WS Denim White Stuff",
"525 america",
"angel jackson",
"Anna Smith",
"Beneunder",
"Bhanuni by Jyoti",
"Brandon Maxwell",
"Dr√¥le de Monsieur",
"ELLANDEMM",
"Fraas",
"Hot Soles",
"IKRUSH",
"Jijil",
"Joey",
"Krisa",
"Lan Ting Bride",
"Liberty Graphics",
"Muxxn",
"Ad Lib",
"Onyx",
"Pam & Gela",
"C&A",
"chianti",
"Rihoas",
"Disaster Designs",
"G21",
"ginatricot",
"Glamorous",
"Lily & Me",
"Millenium",
"Odille",
"Villain London",
"Produkt",
"Vera & Lucy",
])