import axios, { AxiosResponse } from 'axios';
import { listenerCount } from 'node:process';
import qs from 'qs';
import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast';

export type SwymListItem = {
  /**
   * Variant id of the product
   */
  epi: number | string;
  /**
   * Product id of the product
   */
  empi: number | string;
  /**
   * Canonical uri of the product
   */
  du: string;
  /**
   * Defaults to 1. Quantity included for the action
   */
  qty?: number;
  /**
   *
   */
  note?: string; //optional
  cprops?: {}; // Optional custom attributes
  /**
   * Product description
   */
  dt?: string;
  /**
   * Product brand (needs to be confirmed)
   */
  bt?: string;
  /**
   * Price
   */
  pr?: number;
  /**
   * Product Image
   */
  iu?: string;
};

export type SwymList = {
  uid: string;
  /**
   * Created time at in milliseconds
   */
  cts: number;
  /**
   * List Name
   */
  lname: string;
  /**
   * 	Email address of the user if known when list was created
   */
  cby?: string;
  /**
   * Email address of the user if known when list was last updated
   */
  uby?: string;
  /**
   * List id - system generated guid for the list
   */
  lid: string;
  /**
   *  Provider id
   */
  pid: string;
  /**
   * Last updated time at in milliseconds
   */
  uts: number;
  userinfo?: any;
  st?: number;
  listcontents: SwymListItem[];
};

// Reference from https://api-docs.swym.it/v3/index.html#list-definition-input
type SwymPostEndpoints =
  | { path: 'lists/fetch-lists'; data: { regid: string; sessionid: string } }
  | { path: 'lists/create'; data: { regid: string; sessionid: string; lname: string } }
  | { path: 'product/wishlist/social-count'; data: { regid: string; sessionid: string; empi: string | number } }
  | {
      path: 'lists/update-ctx';
      data: {
        regid: string;
        sessionid: string;
        lid: string;
        /**
         * List of items that will be added
         **/
        a?: SwymListItem[];
        /**
         * List of items that will be updated
         **/
        u: SwymListItem[];
        /**
         * List of items that will be deleted
         **/
        d: SwymListItem[];
      };
    };

export namespace SwymService {
  export const swymPostApi = async ({ path, data }: SwymPostEndpoints): Promise<AxiosResponse> => {
    // var form_data = new FormData();
    let newData = { ...data };

    Object.keys(newData).forEach((key) => {
      // The swym api is expecting arrays to just be strings instead of the standard query parameter approach
      if (Array.isArray(newData[key])) {
        newData[key] = JSON.stringify(newData[key]);
      }
    });

    return await axios.post(
      `${process.env.NEXT_PUBLIC_SWYM_API_ENDPOINT}/api/v3/${path}?pid=${encodeURIComponent(
        process.env.NEXT_PUBLIC_SWYM_PID
      )}`,
      qs.stringify(newData),
      {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }
    );
  };
  /**
   * Generate a random UUID needed for non logged in customers
   */
  export const generateAnonymousUUID = () => {
    return uuidv4();
  };

  export interface SwymSessionResp {
    regid: string;
    sessionid: string;
  }

  /**
   * Generate the Reg id for an anonymus user
   */
  export const generateAnonymousSessionRegId = async (uuid: string): Promise<SwymSessionResp> => {
    try {
      const resp = await axios.get<SwymSessionResp>(`/api/wishlist/get-session?uuid=${uuid}`);

      return resp.data;
    } catch (error) {
      console.log('Swym generate anon session error', error);
    }
  };

  /**
   * Generate the Reg id for a logged in user
   */
  export const generateUserSessionRegId = async (email: string): Promise<SwymSessionResp> => {
    try {
      const resp = await axios.get<SwymSessionResp>(`/api/wishlist/get-session?email=${encodeURIComponent(email)}`);

      return resp.data;
    } catch (error) {
      console.log('Swym generate user session error', error);
    }
  };
  /**
   * Generate the Reg id for a logged in user
   */

  export interface SwymSyncSessionResp {
    message: string;
    regid: string;
    email: string;
  }
  export const syncUserSessionRegId = async (email: string, regid: string): Promise<SwymSyncSessionResp> => {
    const resp = await axios.get<SwymSyncSessionResp>(
      `/api/wishlist/sync-session?email=${encodeURIComponent(email)}&regid=${regid}`
    );

    return resp.data;
  };

  export const createCustomerList = async (regid: string, sessionid: string): Promise<SwymList> => {
    const resp = await swymPostApi({
      path: 'lists/create',
      data: { regid, sessionid, lname: 'My Wishlist' },
    });

    return resp.data;
  };

  /**
   * Get all of the lists assigned to this customer
   */
  export const getCustomerList = async (regid: string, sessionid: string): Promise<SwymList> => {
    // TODO: add in error
    const resp = await swymPostApi({ path: 'lists/fetch-lists', data: { regid, sessionid } });
    let list: SwymList = resp.data[0];

    if (!list) {
      // Create a list for the user
      await createCustomerList(regid, sessionid);

      const resp = await swymPostApi({ path: 'lists/fetch-lists', data: { regid, sessionid } });
      list = resp.data[0];
    }

    return list;
  };

  /**
   * Add an item to a specific user list
   */
  export const addItemToList = async (regid: string, sessionid: string, listId: string, listItem: SwymListItem) => {
    const { empi, epi, du } = listItem;
    const resp = await swymPostApi({
      path: 'lists/update-ctx',
      data: { regid, sessionid, lid: listId, a: [{empi, epi, du}], u: [], d: [] },
    });
    const results: any[] = resp.data.a;
    const error = results.find(result => result?.hasError)
    if (error) {
      toast.error("Sorry, we were unable to add this item to your watchlist due to an issue with our provider. We are working on a fix now.")
      throw new Error(`Swym error: ${error.msg}`)
    }
  };

  /**
   * Remove an item from a specific user list
   */
  export const removeItemFromList = async (
    regid: string,
    sessionid: string,
    listId: string,
    listItem: SwymListItem
  ) => {
    const { empi, epi, du } = listItem;
    const resp = await swymPostApi({
      path: 'lists/update-ctx',
      data: { regid, sessionid, lid: listId, d: [{empi, epi, du}], u: [], a: [] },
    });
    const results: any[] = resp.data.d;
    const error = results.find(result => result?.hasError)
    if (error) {
      if (error.msg === "List Item Not Found") return; // already deleted so don't worry about it
      toast.error("Sorry, we were unable to remove this item from your watchlist due to an issue with our provider. We are working on a fix now.")
      throw new Error(`Swym error: ${error.msg}`)
    }
  };

  /**
   * Get the number of wishlists the item is currently in
   */
  export const getWishlistSocialCount = async ({ empi, regid, sessionid}: { empi: string | number, regid: string, sessionid: string }): Promise<{ data: { count: number, empi: string | number } }> => {
    const resp = await swymPostApi({
      path: 'product/wishlist/social-count',
      data: { empi, regid, sessionid },
    });

    return resp.data;
  }
}
