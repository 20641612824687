import { storyblokInit, apiPlugin } from '@storyblok/react';

import { AxiosResponse } from './../../helpers/intl.utility';

// Nested
import Hero from '../../components/storyblok/nested/Hero';
import BlockQuote from '../../components/storyblok/nested/BlockQuote/index';
import Intro from '../../components/storyblok/nested/Intro';
import BenefitsStrip from '../../components/storyblok/nested/BenefitsStrip';
import BenefitsStripItem from '../../components/storyblok/nested/BenefitsStrip/BenefitsStripItem';
import Feature from '../../components/storyblok/nested/Feature/index';
import FeatureList from '../../components/storyblok/nested/FeatureList/index';
import BlockQuoteList from '../../components/storyblok/nested/BlockQuoteList/index';
import Carousel from '../../components/storyblok/nested/Carousel/index';
import CarouselItemImage from '../../components/storyblok/nested/Carousel/CarouselItemImage/index';
import CategorySlider from '../../components/storyblok/nested/CategorySlider/index';
import CategorySliderItem from '../../components/storyblok/nested/CategorySlider/CategorySliderItem/index';
import Countdown from '../../components/storyblok/nested/AnnouncementBar/Countdown';
import Grid from '../../components/storyblok/nested/Grid/index';
import BrandTile from '../../components/storyblok/nested/BrandTile';
import AsSeenInList from '../../components/storyblok/nested/AsSeenInList';
import AsSeenInItem from '../../components/storyblok/nested/AsSeenInItem';
import NewsletterSignup from '../../components/storyblok/nested/NewsletterSignup';
import Instafeed from '../../components/storyblok/nested/Instafeed';
import Search from '../../components/storyblok/nested/Search';
import DrawerItemGrid from '../../components/storyblok/nested/Drawer/DrawerItemGrid';
import DrawerBrandItem from '../../components/storyblok/nested/Drawer/DrawerBrandItem';
import DrawerCategoryItem from '../../components/storyblok/nested/Drawer/DrawerCategoryItem';
import DrawerSignpostList from '../../components/storyblok/nested/Drawer/DrawerSignpostList';
import DrawerSignpostItem from '../../components/storyblok/nested/Drawer/DrawerSignpostItem';
import TrustpilotWidget from '../../components/storyblok/nested/TrustpilotWidget';
import BrandChecker from '../../components/storyblok/nested/BrandChecker';
import PageHero from '../../components/storyblok/nested/PageHero';
import ClearoutOrderItem from '../../components/storyblok/nested/ClearoutOrderItem';
import Teaser from '../../components/storyblok/nested/Teaser';
import HeaderMenu from '../../components/storyblok/nested/HeaderMenu';
import MenuLink from '../../components/storyblok/nested/MenuLink';
import RichTextSection from '../../components/storyblok/nested/RichText';
import AnnouncementBar from '../../components/storyblok/nested/AnnouncementBar';
import InlineHTML from '../../components/storyblok/nested/InlineHTML';
import SaleCountdownBar from '../../components/storyblok/nested/SaleCountdownBar';
import IconGrid from '../../components/storyblok/nested/IconGrid';
import { Watchlist } from '../../components/storyblok/nested/Watchlist';
import { YourRecentSearches } from '../../components/storyblok/nested/YourRecentSearches';
import { YourOffers } from '../../components/storyblok/nested/YourOffers';

// content
import Page from '../../components/storyblok/contentType/Page';
import PartnerPage from '../../components/storyblok/contentType/PartnerPage';
import PayoutFAQs from '../../components/storyblok/contentType/PayoutsAdditionalContent/PayoutFAQs';
import BlogArticle from '../../components/storyblok/contentType/BlogArticle';
import StatHighlightList from '../../components/storyblok/nested/StatHighlightList/index';
import StatHighlightItem from '../../components/storyblok/nested/StatHighlightItem/index';
import CTA from '../../components/storyblok/nested/CTA';
import { BackToTop } from '../../components/storyblok/nested/BackToTop';
import FeatureListAlt from '../../components/storyblok/nested/FeatureListAlt/index';
import DrawerSubcategoryItem from '../../components/storyblok/nested/Drawer/DrawerSubcategoryItem';
import LayoutGrid from '../../components/storyblok/nested/LayoutGrid';
import LayoutList from '../../components/storyblok/nested/LayoutList';
import DrawerAnnouncement from '../../components/storyblok/nested/Drawer/DrawerAnnouncement';
import GetStartedPartnerStep from '../../components/storyblok/nested/GetStartedPartnerStep';
import PartnerCollection from '../../components/storyblok/contentType/PartnerCollection';
import PartnerHero from '../../components/storyblok/nested/PartnerHero';
import PartnerHeader from '../../components/storyblok/nested/PartnerHeader';
import NavLink from '../../components/storyblok/nested/NavLink';
import PartnerFAQList from '../../components/storyblok/nested/PartnerFAQs/PartnerFAQList';
import PartnerFAQSection from '../../components/storyblok/nested/PartnerFAQs/PartnerFAQSection';
import PartnerFAQItem from '../../components/storyblok/nested/PartnerFAQs/PartnerFAQItem';
import PartnerHeroSplit from '../../components/storyblok/nested/PartnerHeroSplit';
import PdpIcons from '../../components/storyblok/nested/PdpIcons';
import PdpIcon from '../../components/storyblok/nested/PdpIcons/PdpIcon';
import ProductAlt from '../../components/storyblok/contentType/ProductAlt/ProductAlt';
import CollectionPageAdditionalContentStoryblok from '../../components/storyblok/contentType/CollectionPageAdditionalContent/CollectionPageAdditionalContent';
import OrderBagPartnerStep from '../../components/storyblok/nested/OrderBagPartnerStep';
import RegisterAccountPartnerStep from '../../components/storyblok/nested/RegisterAccountPartnerStep';
import AutodonatePartnerStep from '../../components/storyblok/nested/AutodonatePartnerStep';
import ComponentIcons from '../../components/storyblok/nested/ComponentIcons';
import { CollectionNewestUploads } from '../../components/storyblok/nested/CollectionNewestUploads';
import { YourRecommendedItems } from '../../components/storyblok/nested/YourRecommendedItems';

export const STORYBLOK_COMPONENT_RESOLVERS = {
  header_menu: HeaderMenu,
  menu_link: MenuLink,
  feature: Feature,
  feature_list: FeatureList,
  feature_list_alt: FeatureListAlt,
  grid: Grid,
  teaser: Teaser,
  hero: Hero,
  global_reference: Hero,
  block_quote: BlockQuote,
  block_quote_list: BlockQuoteList,
  intro: Intro,
  benefits_strip: BenefitsStrip,
  benefits_strip_item: BenefitsStripItem,
  carousel: Carousel,
  carousel_item_image: CarouselItemImage,
  category_slider: CategorySlider,
  category_slider_item: CategorySliderItem,
  brand_tile: BrandTile,
  as_seen_in_list: AsSeenInList,
  as_seen_in_item: AsSeenInItem,
  newsletter_signup: NewsletterSignup,
  instafeed: Instafeed,
  search: Search,
  rich_text: RichTextSection,
  inline_html: InlineHTML,
  trustpilot_widget: TrustpilotWidget,
  back_to_top: BackToTop,
  announcement_bar: AnnouncementBar,
  collection_newest_uploads: CollectionNewestUploads,
  countdown: Countdown,
  get_started_partner_step: GetStartedPartnerStep,
  order_bag_partner_step: OrderBagPartnerStep,
  autodonate_partner_step: AutodonatePartnerStep,
  register_account_partner_step: RegisterAccountPartnerStep,
  partner_header: PartnerHeader,
  partner_hero: PartnerHero,
  partner_hero_split: PartnerHeroSplit,
  partner_faq_list: PartnerFAQList,
  partner_faq_section: PartnerFAQSection,
  partner_faq_item: PartnerFAQItem,
  payouts_additional_content: PayoutFAQs,
  nav_link: NavLink,
  pdp_icons: PdpIcons,
  pdp_icon: PdpIcon,
  salecountdown_bar: SaleCountdownBar,
  collection_page_additional_content: CollectionPageAdditionalContentStoryblok,
  "Icon Grid": IconGrid,
  component_icons: ComponentIcons,
  watchlist: Watchlist,
  your_recent_searches: YourRecentSearches,
  your_offers: YourOffers,
  your_recommended_items: YourRecommendedItems,
  //Shop drawer components
  drawer_item_grid: DrawerItemGrid,
  drawer_brand_item: DrawerBrandItem,
  drawer_category_item: DrawerCategoryItem,
  drawer_subcategory_item: DrawerSubcategoryItem,
  drawer_signpost_list: DrawerSignpostList,
  drawer_signpost_item: DrawerSignpostItem,
  drawer_announcement: DrawerAnnouncement,
  // Brand checker
  resell_form: BrandChecker,
  page_hero: PageHero,
  clearout_order_item: ClearoutOrderItem,
  // Pages
  page: Page,
  product_alt: ProductAlt,
  partner_page: PartnerPage,
  partner_collection: PartnerCollection,
  blog_article: BlogArticle,
  stat_highlight_list: StatHighlightList,
  stat_highlight_item: StatHighlightItem,
  cta: CTA,
  //Layouts
  layout_grid: LayoutGrid,
  layout_list: LayoutList,
};

export const storyInit = async () => {
  storyblokInit({
    accessToken: process.env.NEXT_PUBLIC_STORYBLOK_ACCESS_TOKEN || 'cEBTfFET2xC8WlA78mEU6Qtt',
    use: [apiPlugin],
    components: STORYBLOK_COMPONENT_RESOLVERS,
  });

  return storyblokInit;
};

export const storyBlockAPI = async (starts_with: string) => {
  let config = {
    method: 'get',
    url: `https://api.storyblok.com/v2/cdn/stories?by_slugs=${starts_with}/*&cv=1669777893&token=${process.env.NEXT_PUBLIC_STORYBLOK_ACCESS_TOKEN}&version=published`,
  };
  const data = await AxiosResponse(config);
  return data;
};
