import styles from './styles.module.css';
import { CanonicalItem } from '../../../data-fetching/canonicalItem';
import { Icon, LoadingAnimation } from '../../core';
import { LookingSimilar, useLookingSimilar } from '@algolia/recommend-react';
import { getLooksSameFilters, LooksSimilarHeader, recommendClient, RelatedItem } from '../RelatedProducts';
import { algoliaIndices } from '../../../utils/constants';
import { useEffect, useState } from 'react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  item: CanonicalItem;
}


export const SimilarItemsModal: React.FC<Props> = ({ isOpen, onClose, item }) => {
  const looksSameItemFilters = getLooksSameFilters(item);
  const filtersString = looksSameItemFilters?.filter(Boolean).join(' AND ');
  const [shouldFetch, setShouldFetch] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) setShouldFetch(true)
  }, [isOpen])

  const { recommendations, status } = useLookingSimilar({
    recommendClient,
    indexName: algoliaIndices.shopifyProducts,
    objectIDs: shouldFetch ? [`${item.variant_id}`] : [],
    threshold: 65,
    queryParameters: {
      filters: filtersString && filtersString.length > 0 ? filtersString : 'inventory_available:"true"',
    },
    maxRecommendations: 6,
  });

  if (!isOpen) return null;

  return (
    <div className={styles.overlay} onClick={(e) => { e.target === e.currentTarget ? onClose() : undefined}}>
      <div className={styles.content}>
        <button onClick={onClose} className={styles.closeButton}>
          <Icon title={'close'} color="transparent" />
        </button>
        <div className="bg-brandYellow25">
          {status === "loading" ? (
            <div className="text-center py-6"><LoadingAnimation className='m-auto' /></div>
          ) : recommendations.length > 0 ? (
            <LookingSimilar
              classNames={{
                root: 'px-3 py-6 Container',
                list: 'mt-2 grid gap-y-6 gap-x-2 grid-cols-3 md:grid-cols-6 xl:gap-x-4',
              }}
              recommendClient={recommendClient}
              indexName={algoliaIndices.shopifyProducts}
              objectIDs={[`${item.variant_id}`]}
              queryParameters={{
                filters: filtersString && filtersString.length > 0 ? filtersString : 'inventory_available:"true"',
              }}
              itemComponent={RelatedItem}
              headerComponent={LooksSimilarHeader}
              maxRecommendations={6}
            />
          ) : (
            <p className="text-center py-6">This piece is unique. No similar matches found.</p>
          )}
        </div>
      </div>
    </div>
  );
};