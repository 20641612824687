import { useContext } from "react";
import { AccountContext } from "../../../context/AccountContext";
import { makeCoreRequest } from "../../../utils/account";
import { useQuery, useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { coreApi } from '../../../modules/@core/client';
import { CoreEntities } from '../../../modules/@core/entities';
import { getQueryClient } from '../../../context/QueryContext';

export type CustomerInfo = CoreEntities['CustomerInfo'];

const getCustomer = async () => {
    const response = await coreApi().GET('/customer/v1/customers/me/customer_info', { params: {}})

    if (response.error) throw new Error(response.error.err_msg);

    return response.data
}

export const useCustomer = () => {
    const { data, isLoading: customerLoading } = useQuery({
        queryKey: ['customer'],
        queryFn: getCustomer,
        staleTime: 0,
        gcTime: 0,
    });

    return {
        customer: data,
        isLoading: customerLoading,
    };
};

const updateCustomer = async (update: { 
    bank_details?: {
        account_holder: string,
        sort_code: string,
        account_number: string,
        receiver_type: string,
    } | null,
    email?: string | undefined,
    first_name?: string | undefined,
    last_name?: string | undefined
}) => {
    const options = {
        method: 'PATCH',
        url: `/customers/me/customer_info`,
        data: {bank_details: update.hasOwnProperty('bank_details')
            ? update.bank_details !== null
            ? {
                account_holder: update.bank_details?.account_holder,
                sort_code: update.bank_details?.sort_code,
                account_number: update.bank_details?.account_number,
                receiver_type: update.bank_details?.receiver_type
            } : null
            : undefined,
            email: update.email ?? undefined,
            first_name: update.first_name ?? undefined,
            last_name: update.last_name ?? undefined
        }
    }
    const response = await makeCoreRequest(options);
    if (!response) throw new Error("Session has ended");
    if (!response.ok) throw new Error(response.body.err_msg);
}

export const useUpdateCustomer = (errorMessage: string) => {
    const { getThriftCoreCustomer } = useContext(AccountContext);

    const { mutate, isPending, isSuccess, error } = useMutation( {
        mutationFn: updateCustomer,
        onSuccess: async () => {
            toast.success('Customer updated successfully')
            await getQueryClient().invalidateQueries({ queryKey: ['customer'] });
            await getThriftCoreCustomer()
        },
        onError: (e) => {
            toast.error(errorMessage);
        } 
    })

    return {
        updateCustomer: mutate,
        isLoading: isPending,
        isSuccess,
        error,
    }
}