import { LookingSimilar, RelatedProducts as RecommendedProducts } from '@algolia/recommend-react';
import recommend from '@algolia/recommend';
import { algoliaIndices, genderDepartmentTags } from '../../../utils/constants';
import { Index } from '../../shopify/SimpleItemCard';
import { convertAlgoliaHitToCanonicalItem } from '../../../data-fetching/useAlgoliaHit';
import { CanonicalItem } from '../../../data-fetching/canonicalItem';
import { toOrFilter } from '../../searching/queries/algoliaProductSearch';

export const recommendClient = recommend(
  process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_RECOMMEND_API_KEY
);

export const RelatedItem = ({ item: hit }) => {
  return <Index item={convertAlgoliaHitToCanonicalItem(hit)} />;
};

export const LooksSimilarHeader = () => {
  return <h3 className={'body-18-bold uppercase'}>You may also like</h3>;
};

const RelatedProductsHeader = () => {
  return <h3 className={'body-18-bold uppercase'}>More from this brand</h3>;
};

const getGeneralFilters = (item: CanonicalItem) => {
  return [
    toOrFilter("inventory_available", true),
    toOrFilter("tags", item.department  === "Men's" ? genderDepartmentTags.men : genderDepartmentTags.women),
  ]
}

export const getLooksSameFilters = (item: CanonicalItem) => { 
  if (!item) return null;
  return [
    ...getGeneralFilters(item),
    toOrFilter('tags', item._original.tags.filter((tag: string) => tag.startsWith('Size'))),
  ]
}

const RelatedProducts: React.FC<{item: CanonicalItem}> = ({ item }) => {
  const generalFilters = [
    toOrFilter("inventory_available", true),
    toOrFilter("tags", item.department  === "Men's" ? genderDepartmentTags.men : genderDepartmentTags.women),
  ]
  const looksSameFilters = [
    ...generalFilters,
    toOrFilter('tags', item._original.tags.filter((tag: string) => tag.startsWith('Size'))),
  ]

  const moreFromBrandFilters = [
    ...generalFilters,
    toOrFilter('tags', item._original.tags.filter((tag: string) => tag.startsWith('Brand'))),
  ]

  return (
    <div className="bg-brandYellow25">
      <LookingSimilar
        classNames={{
          root: 'px-3 py-6 Container',
          list: 'mt-2 grid gap-y-6 gap-x-2 grid-cols-3 md:grid-cols-6 xl:gap-x-4',
        }}
        threshold={65}
        recommendClient={recommendClient}
        indexName={algoliaIndices.shopifyProducts}
        objectIDs={[`${item.variant_id}`]}
        queryParameters={{
          filters: looksSameFilters.join(' AND ')
        }}
        itemComponent={RelatedItem}
        headerComponent={LooksSimilarHeader}
        maxRecommendations={12}
      />

      <RecommendedProducts
        classNames={{
          root: 'px-3 py-6 Container',
          list: 'mt-2 grid gap-y-6 gap-x-2 grid-cols-3 md:grid-cols-6 xl:gap-x-4',
        }}
        recommendClient={recommendClient}
        indexName={algoliaIndices.shopifyProducts}
        objectIDs={[`${item.variant_id}`]}
        queryParameters={{
          filters: moreFromBrandFilters.join(' AND ')
        }}
        itemComponent={RelatedItem}
        headerComponent={RelatedProductsHeader}
        maxRecommendations={12}
      />
    </div>
  );
};

export default RelatedProducts;
