import React, { useContext } from 'react';
import { Button } from '../../../../../core';
import { WishlistContext } from '../../../../../../context/WishlistContext';
import { CanonicalItem } from '../../../../../../data-fetching/canonicalItem';
import { SwymListItem } from '../../../../../../modules/@swym/swym.service';

export const RemoveFromWishlistButton: React.FC<{watchlistItem?: SwymListItem}> = ({ watchlistItem}) => {
    const { removeItemFromWishlist } = useContext(WishlistContext);


    if (!watchlistItem) return null;
    return (
        <Button
          className="w-1/2"
          onClick={() => {
            removeItemFromWishlist(watchlistItem, true);
          }}
          iconLeft="heartWishRemove"
          text="remove"
          isSecondary
        />
    )
}