import { createContext, useContext, useEffect, useState } from 'react';
import { makeCoreRequest } from '../utils/account';
import { useCookies } from 'react-cookie';
import { CustomerAccessToken, MailingAddress, ShopifyService } from '../modules/shopify.service';
import { useRouter } from 'next/router';
import { WishlistContext } from './WishlistContext';
import { Tracking } from '../utils/tracking';
import { fetchPostJSON } from '../utils/api-helpers';
import { useCookieConsent } from '../components/sections/CookieConsent/useCookieConsent';
import { useValueHasChanged } from '../components/sections/CookieConsent/useValueHasChanged';
import { usePrimaryCartExists } from '../data-fetching/cart/usePrimaryCart';
import toast from 'react-hot-toast';

/**
 * Types
 */

export interface Customer {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  chosen_nonprofit_name: string;
  chosen_nonprofit_id: string;
  farfetch_account: string;
  shopify_customer_id: string;
  credits: number;
  reviewable_item_count: number;
  smile_token: string;
  accepts_marketing: boolean;
  paypal_account_email: string | null;
  pending_credits: {
    donation_amount_in_pence: number,
    credit_amount_in_pence: number,
  },
  bank_details: {
    account_holder: string | null,
    account_number: string | null,
    sort_code: string | null,
    receiver_type: string | null,
  }
}

export interface AccountContextType {
  customerDetails: Customer;
  setCustomerDetails: ({}: Customer) => void;
  loginShopifyCustomer: (email: string, password: string, redirect?: string | null) => void;
  logoutShopifyCustomer: () => void;
  registerShopifyCustomer: (firstName: string, lastName: string, email: string, password: string, acceptsMarketing: boolean, redirect?: string | null) => void;
  recoverShopifyPasswordCustomer: (email: string) => Promise<boolean>;
  resetShopifyPasswordCustomer: (
    password: string,
    confirmPassword: string,
    customerId: string,
    resetToken: string
  ) => Promise<boolean>;
  activateShopifyCustomer: (
    password: string,
    confirmPassword: string,
    customerId: string,
    activateToken: string
  ) => Promise<boolean>;
  updateShopifyCustomer: (params: {
    acceptsMarketing: boolean;
  }) => Promise<boolean>;
  shopifyCustomerError: string | null;
  setShopifyCustomerError: (error: string | null) => void;
  isCustomerLoading: boolean;
  isLoggedIn: boolean;
  isLoggedInDynamic: boolean;
  customerToken: string;
  reviewableItemCount: number;
  isCoreLoading: boolean;
  setReviewableItemCount: (count: number) => void;
  getThriftCoreCustomer: () => Promise<void>;
  updateCustomerAddress: (params: {
    customerAddress: MailingAddress;
  }) => Promise<boolean>;
  isCustomerAddressLoading: boolean;
}

const AccountContext = createContext<AccountContextType>(undefined as any);

/**
 * Provider
 */

const initialCustomerState = {
  id: '',
  email: '',
  first_name: '',
  last_name: '',
  chosen_nonprofit_name: '',
  chosen_nonprofit_id: '',
  farfetch_account: '',
  shopify_customer_id: '',
  credits: 0,
  reviewable_item_count: 0,
  smile_token: '',
  accepts_marketing: false,
  paypal_account_email: '',
  pending_credits: {
    donation_amount_in_pence: 0,
    credit_amount_in_pence: 0,
  },
  bank_details: {
    account_holder: '',
    account_number: '',
    sort_code: '',
    receiver_type: 'person',
  }
}

const AccountProvider = ({ children }) => {
  const [cookieConsent] = useCookieConsent();
  const [customerDetails, setCustomerDetails] = useState<Customer>(initialCustomerState);

  const [reviewableItemCount, setReviewableItemCount] = useState(0); // Probably not needed as its in the customer details
  const [cookies, setCookie, removeCookie] = useCookies(['customerAccessToken', 'customerId']);
  const [shopifyCustomerError, setShopifyCustomerError] = useState<string | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCoreLoading, setIsCoreLoading] = useState<boolean>(true);
  const [isCustomerAddressLoading, setIsCustomerAddressLoading] = useState<boolean>(false);
  const { tryMergeWishlistsAndLogin, logoutUserFromWishlist, retrieveOrCreateAnonSession, anonymousWishlistUUID } = useContext(WishlistContext);
  const router = useRouter();
  usePrimaryCartExists();
  const isLoggedIn = !!cookies.customerAccessToken;

  const [isLoggedInDynamic, setIsLoggedInDynamic] = useState(false); // use this to render content dynamically & prevent hydration error

  useEffect(() => {
    setIsLoggedInDynamic(isLoggedIn);

    if (!isLoggedIn) {
      retrieveOrCreateAnonSession();
      setIsCoreLoading(false);
    } else {
      getThriftCoreCustomer();
    }
  }, [isLoggedIn]);

  const initSmileUser = async () => {
    //@ts-ignore
    if (typeof window !== 'undefined' && window.SmileUI) {
      if (customerDetails?.shopify_customer_id) {
        try {
          // Get user details & login
          const { signature } = await fetchPostJSON('/api/smile', { userId: customerDetails?.shopify_customer_id });
          //@ts-ignore
          window.SmileUI.init({
            channel_key: 'channel_6p6GEDSwxYxtCJLglD1Z3zDc',
            customer_identity_jwt: signature,
          });
        } catch (err) {}
      } else {
        // Reset user
        //@ts-ignore
        window.SmileUI.init({
          channel_key: 'channel_6p6GEDSwxYxtCJLglD1Z3zDc',
        });
      }
    } else {
      // if the window object doesn't exist yet, then run again
      setTimeout(initSmileUser, 2000);
    }
  };

  useEffect(() => {
    if (!cookieConsent?.analytics_storage) {
      Tracking.setAuthenticatedAlgoliaUserToken(undefined);
      setCookie('customerId', undefined, {
        maxAge: 60 * 60 * 24 * 90, // 90 days
        sameSite: 'strict',
        path: '/'
      })
      return;
    }
    if (customerDetails?.id) {
      Tracking.setAuthenticatedAlgoliaUserToken(`${customerDetails.id}`);
      Tracking.setAlgoliaUserToken(`${customerDetails.id}`);
      setCookie('customerId', `${customerDetails.id}`, {
        maxAge: 60 * 60 * 24 * 90, // 90 days
        sameSite: 'strict',
        path: '/'
      })
    } else {
      Tracking.setAuthenticatedAlgoliaUserToken(undefined);
      setCookie('customerId', undefined, {
        maxAge: 60 * 60 * 24 * 90, // 90 days
        sameSite: 'strict',
        path: '/'
      })
    }
    if (anonymousWishlistUUID && !customerDetails?.id) {
      Tracking.setAlgoliaUserToken(anonymousWishlistUUID);
    }
  }, [customerDetails, anonymousWishlistUUID, cookieConsent?.analytics_storage]);

  useEffect(() => {
    initSmileUser();
  }, [customerDetails?.shopify_customer_id]);

  /**
   * Customer constext
   */

  const getThriftCoreCustomer = async () => {
    setIsCoreLoading(true);
    try {
      const options = {
        method: 'GET',
        url: `/customers/me/customer_info?cache_bypass=` + Date.now(),
      };

      const response = await makeCoreRequest(options);

      if (response && response.ok) {
        Tracking.identifyUser(response.body);
        setCustomerDetails(response.body);
        setReviewableItemCount(response.body.reviewable_item_count || -1);
        if (typeof window !== 'undefined' && window['ReactNativeWebView']) {
          window['ReactNativeWebView'].postMessage(JSON.stringify({ type: 'account-login', data: response.body }));
        }
      } else {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
    setIsCoreLoading(false);
  };

  const loginShopifyCustomer = async (email: string, password: string, redirect?: string | null) => {
    setIsLoading(true);
    try {
      const resp = await ShopifyService.loginCustomer({ email, password });

      if (resp.customerAccessTokenCreate.userErrors.length > 0) {
        setShopifyCustomerError('Email or password not matching');
      } else {
        setShopifyCustomerError(null);
        setCookie('customerAccessToken', resp.customerAccessTokenCreate.customerAccessToken.accessToken, {
          expires: new Date(resp.customerAccessTokenCreate.customerAccessToken.expiresAt),
          path: '/',
        });
        await tryMergeWishlistsAndLogin(email);

        if (redirect) {
          router.push(decodeURIComponent(redirect), null, { shallow: true });
        } else {
          await getThriftCoreCustomer();
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const registerShopifyCustomer = async (firstName: string, lastName: string, email: string, password: string, acceptsMarketing: boolean, redirect?: string | null) => {
    setIsLoading(true);

    if (!/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/.test(password)) {
      setShopifyCustomerError(
        'Please ensure your password is secure (includes uppercase & lowercase letter, a digit & a special character)'
      );
      setIsLoading(false);
      return;
    }

    try {
      const resp = await ShopifyService.createCustomer({ firstName, lastName, email, password, acceptsMarketing });
      if (resp.customerCreate.userErrors.length > 0) {
        setShopifyCustomerError(resp.customerCreate.userErrors.map((err) => err.message).join(','));
      } else {
        setShopifyCustomerError(null);
        await loginShopifyCustomer(email, password, redirect === null ? null : (redirect || '/account'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const recoverShopifyPasswordCustomer = async (email: string): Promise<boolean> => {
    setIsLoading(true);
    let isSuccess = false;
    try {
      const resp = await ShopifyService.recoverPasswordCustomer({ email });

      if (resp.customerRecover.customerUserErrors.length > 0) {
        setShopifyCustomerError(resp.customerRecover.customerUserErrors.map((err) => err.message).join(','));
      } else {
        isSuccess = true;
        setShopifyCustomerError(null);
      }
    } finally {
      setIsLoading(false);
    }

    return isSuccess;
  };

  const resetShopifyPasswordCustomer = async (
    password: string,
    confirmPassword: string,
    customerId: string,
    resetToken: string
  ): Promise<boolean> => {
    if (password !== confirmPassword) {
      setShopifyCustomerError('The password confirmation must match the provided password');
      return false;
    }

    setIsLoading(true);
    let isSuccess = false;
    try {
      const resp = await ShopifyService.resetPasswordCustomer({
        id: `gid://shopify/Customer/${customerId}`,
        input: { resetToken, password },
      });

      if (resp.customerReset.customerUserErrors.length > 0) {
        setShopifyCustomerError(resp.customerReset.customerUserErrors.map((err) => err.message).join(','));
      } else {
        isSuccess = true;
        setShopifyCustomerError(null);
      }
    } finally {
      setIsLoading(false);
    }

    return isSuccess;
  };
  
  const updateCustomerAddress = async ({customerAddress}: {customerAddress: MailingAddress}): Promise<boolean> => {
    setIsCustomerAddressLoading(true);
    let isSuccess = false;

    try {
      const resp = await ShopifyService.customerAddressUpdate({
        id: customerAddress.id,
        customerAccessToken: cookies.customerAccessToken,
        address: {
          firstName: customerAddress.firstName,
          lastName: customerAddress.lastName,
          address1: customerAddress.address1,
          address2: customerAddress.address2,
          city: customerAddress.city,
          zip: customerAddress.zip,
        },
      });
      if (resp.customerAddressUpdate?.customerUserErrors && resp.customerAddressUpdate.customerUserErrors.length > 0) {
        setShopifyCustomerError(resp.customerAddressUpdate.customerUserErrors.map((err) => err.message).join(','));
        toast.error('Error updating customer address - please try again')
      } else {
        isSuccess = true;
        toast.success('Address update successful')
        setShopifyCustomerError(null);
      }
    } finally {
      setIsCustomerAddressLoading(false);
    }

    return isSuccess;
  }

  const updateShopifyCustomer = async ({acceptsMarketing} :{acceptsMarketing: boolean}
  ): Promise<boolean> => {
    setIsLoading(true);
    let isSuccess = false;

    try {
      const resp = await ShopifyService.customerUpdate({
        customerAccessToken: cookies.customerAccessToken,
        customer: {
          acceptsMarketing,
        },
      });

      if (resp.customerUpdate.userErrors.length > 0) {
        setShopifyCustomerError(resp.customerUpdate.userErrors.map((err) => err.message).join(','));
      } else {
        isSuccess = true;
        await getThriftCoreCustomer();
        setShopifyCustomerError(null);
        // const updatedCustomerMarketingPrefs = { ...customerDetails, accepts_marketing: acceptsMarketing };

        // setCustomerDetails(updatedCustomerMarketingPrefs);
          }
        } finally {
          setIsLoading(false);
          return isSuccess;
        };
    };

  const activateShopifyCustomer = async (
    password: string,
    confirmPassword: string,
    customerId: string,
    activationToken: string
  ): Promise<boolean> => {
    if (password !== confirmPassword) {
      setShopifyCustomerError('The password confirmation must match the provided password');
      return false;
    }

    setIsLoading(true);
    let isSuccess = false;
    try {
      const resp = await ShopifyService.customerActivate({
        id: `gid://shopify/Customer/${customerId}`,
        input: { activationToken, password },
      });

      if (resp.customerActivate.customerUserErrors.length > 0) {
        setShopifyCustomerError(resp.customerActivate.customerUserErrors.map((err) => err.message).join(','));
      } else {
        isSuccess = true;

        if (resp.customerActivate.customerAccessToken.accessToken) {
          // Activation was successfull  so login & redirect
          setCookie('customerAccessToken', resp.customerActivate.customerAccessToken.accessToken, {
            expires: new Date(resp.customerActivate.customerAccessToken.expiresAt),
            path: '/',
          });
          router.push('/account', null, { shallow: true });
        }

        setShopifyCustomerError(null);
      }
    } finally {
      setIsLoading(false);
    }

    return isSuccess;
  };

  const logoutShopifyCustomer = async () => {
    await logoutUserFromWishlist(); // Reset the wishlist

    await ShopifyService.logoutCustomer({ customerAccessToken: cookies.customerAccessToken })
    document.cookie = 'customerAccessToken=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;';

    await Tracking.unidentifyUser();

    if (typeof window !== 'undefined' && window['ReactNativeWebView']) {
      window['ReactNativeWebView'].postMessage(JSON.stringify({ type: 'account-logout' }));
    }

    setCustomerDetails(initialCustomerState);
    window.location.href = '/';
  };

  return (
    <AccountContext.Provider
      value={{
        customerDetails,
        setCustomerDetails,
        reviewableItemCount,
        setReviewableItemCount,
        getThriftCoreCustomer,
        logoutShopifyCustomer,
        resetShopifyPasswordCustomer,
        recoverShopifyPasswordCustomer,
        registerShopifyCustomer,
        activateShopifyCustomer,
        updateShopifyCustomer,
        loginShopifyCustomer,
        shopifyCustomerError,
        setShopifyCustomerError,
        isLoggedIn,
        isLoggedInDynamic,
        isCoreLoading,
        customerToken: cookies.customerAccessToken,
        isCustomerLoading: isLoading || isCoreLoading,
        updateCustomerAddress,
        isCustomerAddressLoading
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export { AccountContext, AccountProvider };