import { createContext, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { SwymList, SwymListItem, SwymService } from '../modules/@swym/swym.service';
import { clearLocalStorageValue, getLocalStorageValue, setLocalStorageValue } from '../utils/localStorage';
import { Tracking } from '../utils/tracking';
import { CanonicalItem, isCanonicalItem } from '../data-fetching/canonicalItem';
import * as Sentry from "@sentry/nextjs";
import { useCookieConsent } from '../components/sections/CookieConsent/useCookieConsent';
import { algoliaInsights } from '../components/searching/insights';
import { algoliaIndices } from '../utils/constants';
import { getSessionStorageValue } from '../utils/sessionStorage';

export interface UpdateProductData {
  variantId: string | number;
  productId: string | number;
  slug: string;
  name: string;
  price: number;
  brand?: string;
}

type CartItemOrProductOrSwymItem = UpdateProductData | CanonicalItem | SwymListItem;

const getProductIdFromCartItemOrProduct = (cartItemOrProduct: CartItemOrProductOrSwymItem) => {
  if (typeof (cartItemOrProduct as UpdateProductData)['productId'] !== 'undefined') {
    return  (cartItemOrProduct as UpdateProductData)['productId'];
  } else if (typeof (cartItemOrProduct as CanonicalItem)['id'] !== 'undefined') {
    return (cartItemOrProduct as CanonicalItem)['id'];
  } else return undefined;
};

const isProductData = (product: CartItemOrProductOrSwymItem): product is UpdateProductData => {
  const hasVariantIdKey = typeof (product as any)['variantId'] !== 'undefined';
  return hasVariantIdKey;
}

const isSwymWishlistItem = (product: CartItemOrProductOrSwymItem): product is SwymListItem => {
  return typeof (product as any)['empi'] !== 'undefined';
}

export const getWishlistItemHandle = (item: SwymListItem) => {
  const urlArr = item.du.split('/');
  const handle = urlArr[urlArr.length - 1].split('?')[0];
  return handle;
}

export interface WishlistContextType {
  wishlist?: SwymList;
  toggleItemInWishlist: (productData: CartItemOrProductOrSwymItem) => Promise<void>;
  showWishlistHeaderTooltip: boolean;
  anonymousWishlistUUID?: string;
  wishlistLoading: boolean;
  addItemToWishlist: (item: CartItemOrProductOrSwymItem) => Promise<void>;
  itemIsInWishlist: (productData: CartItemOrProductOrSwymItem) => boolean;
  tryMergeWishlistsAndLogin: (accountEmailAddress: string) => Promise<void>;
  logoutUserFromWishlist: () => Promise<void>;
  createAnonSession: () => Promise<void>;
  retrieveOrCreateAnonSession: () => Promise<void>;
  getItemWatchCount: (productId: string | number) => Promise<number>;
  removeItemFromWishlist: (item: CartItemOrProductOrSwymItem, optimistic?: boolean) => Promise<void>;
}

const WishlistContext = createContext<WishlistContextType>(undefined as any);

const WishlistProvider: React.FC<PropsWithChildren> = ({ children }) => {
  // TODO: test localstorage on mobile app
  const [cookies, setCookie, removeCookie] = useCookies(['wishlistSessionId', 'wishlistRegId']);
  const [cookieConsent] = useCookieConsent();
  const [showWishlistHeaderTooltip, setShowWishlistHeaderTooltip] = useState(false);
  const headerTimer = useRef<any>();
  const [anonymousCustomerReference, setAnonymousCustomerReference] = useState<string | undefined>(
    getLocalStorageValue('wishlistUUID')
  );

  const [regId, setRegId] = useState<string | undefined>(cookies.wishlistRegId);
  const [wishlist, setWishlist] = useState<SwymList | undefined>();
  const [shouldRefreshWishlist, setRefreshWishlist] = useState(true);
  const [sessionId, setSessionId] = useState<string | undefined>(cookies.wishlistSessionId);
  const [wishlistLoading, setWishlistLoading] = useState(false);

  /**
   * Set the session data in the context
   */
  const setWishlistSession = (swymSession: SwymService.SwymSessionResp) => {
    const futureDate = new Date();
    futureDate.setMonth(futureDate.getMonth() + 24);
    setCookie('wishlistSessionId', swymSession.sessionid, { path: '/', expires: futureDate });
    setCookie('wishlistRegId', swymSession.regid, { path: '/', expires: futureDate });
    setRegId(swymSession.regid);
    setSessionId(swymSession.sessionid);
    setRefreshWishlist(true);
  };

  const clearPersistentWishlistSession = () => {
    setCookie('wishlistSessionId', undefined, { path: '/', expires: new Date() });
    setCookie('wishlistRegId', undefined, { path: '/', expires: new Date() });
    clearLocalStorageValue('wishlistUUID'); // this is not a wishlist id, it's named wrongly
  }

  const retrieveOrCreateAnonSession = () => {
    if (cookies.wishlistRegId && cookies.wishlistSessionId) {
      setWishlistSession({ regid: cookies.wishlistRegId, sessionid: cookies.wishlistSessionId });
      return;
    }
    return createAnonSession();
  }

  const createAnonSession = async () => {
    const newAnonCustomerReference = SwymService.generateAnonymousUUID();
    setLocalStorageValue('wishlistUUID', newAnonCustomerReference); // this is not a wishlist id, it's named wrongly
    setAnonymousCustomerReference(newAnonCustomerReference);
    const swymSession = await SwymService.generateAnonymousSessionRegId(newAnonCustomerReference);
    setWishlistSession(swymSession);
  }

  const tryMergeWishlistsAndLogin = async (accountEmailAddress: string) => {
    try {
      const syncedSession = await SwymService.syncUserSessionRegId(accountEmailAddress, regId);
      await setWishlistSession({ ...syncedSession, sessionid: sessionId });
    } catch (err) {
      console.error('failed to merge wishlist with account!');
      const newUserSession = await SwymService.generateUserSessionRegId(accountEmailAddress);
      await setWishlistSession(newUserSession);
    }
  }

  const logoutUserFromWishlist = async () => {
    clearPersistentWishlistSession();
    await createAnonSession();
  }

  /**
   * Show Header tooltip for a number of seconds
   */
  const showHeaderUpdate = () => {
    clearTimeout(headerTimer.current);
    setShowWishlistHeaderTooltip(true);
    headerTimer.current = setTimeout(() => {
      setShowWishlistHeaderTooltip(false);
    }, 5000);
  };

  const refreshLocalWishlistFromRemote = async (registrationId = regId, wishlistSessionId = sessionId ) => {
    if (!registrationId || !wishlistSessionId) return;
    const list = await SwymService.getCustomerList(registrationId, wishlistSessionId);
    setWishlist(list);
  };

  const mapProductToSwymItem = (productData: CartItemOrProductOrSwymItem): SwymListItem => {
    if (isProductData(productData)) {
      return {
        epi: productData.variantId,
        empi: productData.productId,
        du: `https://thrift.plus/products/${productData.slug}`,
        bt: productData.brand,
        pr: productData.price,
        dt: productData.name

      };
    } else if (isCanonicalItem(productData)){
      return {
        epi: productData.variant_id,
        empi: productData.id,
        du: `https://thrift.plus/products/${productData.handle}`,
        bt: productData.brand,
        pr: productData.price,
        dt: productData.title
      };
    } else if (isSwymWishlistItem(productData)) {
      return productData;
    }
  }

  const removeItemFromWishlist = async (productData: CartItemOrProductOrSwymItem, optimistic?: boolean) => {
    try {
      const swymItem = mapProductToSwymItem(productData);
      await SwymService.removeItemFromList(regId, sessionId, wishlist.lid, swymItem);

      if (optimistic) {
        setWishlist({
          ...wishlist,
          listcontents: (wishlist.listcontents || []).filter((itm) => itm.epi !== swymItem.epi),
        });
      }

      setRefreshWishlist(true);
    } catch (err) {
      Sentry.captureException(err);
    }

  }

  const addItemToWishlist = async (productData: CartItemOrProductOrSwymItem) => {
    try {
      const swymItem = mapProductToSwymItem(productData);
      await SwymService.addItemToList(regId, sessionId, wishlist.lid, swymItem);

      showHeaderUpdate();
      // Already update locally
      Tracking.AddToWishlist({
        item_id: swymItem.empi,
        item_name: swymItem.dt,
        price: swymItem.pr,
        item_brand: swymItem.bt
      });

      // record wishlist event as conversion in Algolia
      // only canonical item type has algolia hit id currently
      if (cookieConsent?.analytics_storage) {
          const current = getSessionStorageValue('productHitIds');
          const id = getProductIdFromCartItemOrProduct(productData);
          if (!id) {
            Sentry.captureMessage(`No product id retrievable from product data: ${productData}`);
          } else {
            const hit = current?.[id];
  
            if (hit && hit?.queryId?.length > 0) {
              algoliaInsights('convertedObjectIDsAfterSearch', {
                //Triggers algolia insights conversion event
                index: algoliaIndices.shopifyProducts,
                eventName: 'Product Added to Wishlist',
                queryID: hit.queryId,
                objectIDs: [hit.objectId]
              });
            }
          }
      }

      setWishlist({
        ...wishlist,
        listcontents: [...(wishlist.listcontents || []), swymItem],
      });

      // Add item to list
      setRefreshWishlist(true);
    } catch(err) {
      Sentry.captureException(err);
    }

  }

  const itemIsInWishlist = (productData: CartItemOrProductOrSwymItem) => {
    const swymItem = mapProductToSwymItem(productData);
    return !!wishlist?.listcontents?.find((itm) => `${itm.epi}` === `${swymItem.epi}`);
  }

  const toggleItemInWishlist = async (productData: CartItemOrProductOrSwymItem) => {
    if (wishlist?.lid) {
      if (itemIsInWishlist(productData)) {
        await removeItemFromWishlist(productData, true);
      } else {
        await addItemToWishlist(productData);
      }
      // Refresh the wishlist
    } else {
      // TODO: what to do if wishlist doesn't exist
    }
  };

  const getItemWatchCount = useCallback((productId: string | number) => {
    return SwymService
      .getWishlistSocialCount({ empi: productId, regid: regId, sessionid: sessionId})
      .then(response => response.data.count);
  }, [regId, sessionId]);

  // make sure we refresh the wishlist whenever anything changes
  useEffect(() => {
    if (shouldRefreshWishlist) {
      setRefreshWishlist(false);
      refreshLocalWishlistFromRemote();
    }
  }, [shouldRefreshWishlist]);

    return (
      <WishlistContext.Provider
        value={{
          wishlist,
          toggleItemInWishlist,
          tryMergeWishlistsAndLogin,
          logoutUserFromWishlist,
          createAnonSession,
          retrieveOrCreateAnonSession,
          showWishlistHeaderTooltip,
          anonymousWishlistUUID: anonymousCustomerReference,
          wishlistLoading,
          itemIsInWishlist,
          addItemToWishlist,
          getItemWatchCount,
          removeItemFromWishlist,
        }}
      >
        {children}
      </WishlistContext.Provider>
    );
  }


export { WishlistContext, WishlistProvider };
